import React, { useEffect, useState } from "react";
import "./NextGlobalImprovementsSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";
import {
  resetPopUp,
  setAppTheme,
  showPopup,
} from "../../../../../redux/actions/app";
import { createSurvey } from "../../../../../core/axios/services";
import { logoutUser } from "../../../../../redux/actions/user";
import Loader from "../../../../components/Loader/Loader";

const NextGlobalImprovementsSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();
  const { previousId } = useParams();
  const dispatch = useDispatch();
  const [toSave, setToSave] = useState(false);

  const getOveralHealthForCurrentLanguage = () => {
    return survey?.settings?.clinic?.overallHealths.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  const initialConcerns = [
    {
      name: "overallCurrent",
      topText: getOveralHealthForCurrentLanguage()?.question1Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question1Title,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.globalHealthOverallCurrent,
      }),
      leftOption: getOveralHealthForCurrentLanguage()?.question1LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question1HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[7]?.concernRate
          ? survey.data.globalHealth[7].concernRate
          : 0,
      mandatory: false,
    },
    {
      name: "overallDesired",
      topText: getOveralHealthForCurrentLanguage()?.question2Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question2Title,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.globalHealthOverallDesired,
      }),
      leftOption: getOveralHealthForCurrentLanguage()?.question2LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question2HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[8]?.concernRate
          ? survey.data.globalHealth[8].concernRate
          : 0,
      mandatory: false,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateSurveyPage(2));
    let rightConcerns = initialConcerns;
    let concernIndex = 0;
    survey.settings.bodyMetrics.reverse().forEach((metric, i) => {
      const index = rightConcerns.findIndex((c) => c.title === metric.title);
      if (index === -1) {
        let rate = 0;
        switch (concernIndex) {
          case 9:
            rate = survey.previousSurveyData.globalHealthNeck;
            break;
          case 8:
            rate = survey.previousSurveyData.globalHealthMidBack;
            break;
          case 7:
            rate = survey.previousSurveyData.globalHealthLowerBack;
            break;
          case 6:
            rate = survey.previousSurveyData.globalHealthPosture;
            break;
          case 5:
            rate = survey.previousSurveyData.globalHealthEnergyLevels;
            break;
          case 4:
            rate = survey.previousSurveyData.globalHealthAbilityStress;
            break;
          case 3:
            rate = survey.previousSurveyData.globalHealthSleepQuality;
            break;
          case 2:
            rate = survey.previousSurveyData.globalHealthImmuneSystem;
            break;
          case 1:
            rate = survey.previousSurveyData.globalHealthMentalState;
            break;
          case 0:
            rate = survey.previousSurveyData.globalHealthEmotionalState;
            break;
        }
        rightConcerns.unshift({
          name: metric.title,
          topText: metric.title,
          text: i18n.t("youRatedItLastTime", { rate: rate }),
          headline: metric.question,
          concernRate:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[i]?.concernRate
              ? survey.data.globalHealth[i].concernRate
              : 0,
          leftOption: metric.lowScaleName,
          rightOption: metric.highScaleName,
          mandatory: false,
          hasTextInput: false,
          hasRate: true,
        });
      }
      concernIndex++;
    });

    setConcerns(rightConcerns);

    if (survey.data == null) {
      survey.data = { globalHealth: rightConcerns };
    } else {
      survey.data.globalHealth = rightConcerns;
    }

    dispatch(updateSurveyData(survey.data));

    const activeQuestions = survey.settings.openEndedQuestions.filter(
      (o) => o.isActive === true
    ).length;
    if (activeQuestions === 0) {
      setToSave(true);
    }
  }, []);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "completeSurvey":
          sendSurveyToApi();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }

    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

   const [isSendingSurveyToApi, setIsSendingSurveyToApi] = useState(false);
  const sendSurveyToApi = async () => {
    setIsSendingSurveyToApi(true);
    try {
      return await createSurvey(survey).then((res) => {
        setIsSendingSurveyToApi(false);
        dispatch(updateSurveyPage(5));
        navigate("/survey-next/" + code + "/" + previousId + "/thank-you");
      });
    } catch (error) {
      setIsSendingSurveyToApi(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const save = () => {
    if (!checkIfBodyMetricsSelected()) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteBodyMetrics"), 2000);
      return;
    }
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmCompleteSurvey"),
        confirmButton: i18n.t("confirmCompleteSurveyButton"),
        denyButton: i18n.t("denyCompleteSurveyButton"),
        type: "completeSurvey",
      },
    };
    dispatch(showPopup(data));
  };

  const checkIfBodyMetricsSelected = () => {
    if (
      concerns[0]?.concernRate !== 0 &&
      concerns[1]?.concernRate !== 0 &&
      concerns[2]?.concernRate !== 0 &&
      concerns[3]?.concernRate !== 0 &&
      concerns[4]?.concernRate !== 0 &&
      concerns[5]?.concernRate !== 0 &&
      concerns[6]?.concernRate !== 0 &&
      concerns[7]?.concernRate !== 0 &&
      concerns[8]?.concernRate !== 0 &&
      concerns[9]?.concernRate !== 0 &&
      concerns[10]?.concernRate !== 0 &&
      concerns[11]?.concernRate !== 0
    ) {
      return true;
    }
  };

  const nextPage = () => {
    if (!checkIfBodyMetricsSelected()) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteBodyMetrics"), 2000);
      return;
    }
    dispatch(updateSurveyPage(3));
    navigate(
      "/survey-next/" + code + "/" + previousId + "/health-improvements"
    );
  };

  const previousPage = () => {
    dispatch(updateSurveyPage(1));
    navigate("/survey-next/" + code + "/" + previousId + "/health-concerns");
  };

  const updateConcern = (index, value, type) => {
    if (type === "text") {
      concerns[index].concern = value;
    } else {
      concerns[index].concernRate = value;
    }

    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { globalHealth: concerns };
    } else {
      survey.data.globalHealth = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 2"}
        headline={getOveralHealthForCurrentLanguage()?.title}
        text={getOveralHealthForCurrentLanguage()?.desc}
        videoLink={getOveralHealthForCurrentLanguage()?.videoUrl}
        buttonText={i18n.t("next")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              <HealthConcernText
                setConcernText={(value) => updateConcern(index, value, "text")}
                setConcernRate={(value) => updateConcern(index, value, "rate")}
                mandatory={concern.mandatory}
                text={concern.text}
                concernRate={concern.concernRate}
                topText={concern.topText}
                headline={concern.headline}
                themeColor={themeColor}
                hasTextInput={false}
                leftOption={concern.leftOption}
                rightOption={concern.rightOption}
                id={concern.name}
                i18n={i18n}
              />
            </div>
          );
        })}
      </div>
      {
          isSendingSurveyToApi && <Loader/>
      }
      <div className={"buttonsContainer"}>
        {toSave ? (
          <div className={"nextContainer"} onClick={() => save()}>
            <Button
                disabled={isSendingSurveyToApi}
              color={themeColor}
              mode={"small"}
              text={i18n.t("completeSurvey")}
            />
          </div>
        ) : (
          <div className={"nextContainer"} onClick={() => nextPage()}>
            <Button
                disabled={isSendingSurveyToApi}
              color={themeColor}
              mode={"small"}
              text={i18n.t("next")}
            />
          </div>
        )}
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previous")} />
        </div>
      </div>
    </div>
  );
};

export default NextGlobalImprovementsSurvey;
