import React, { useEffect, useState } from "react";
import "./GlobalImprovementsSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";
import {
  resetPopUp,
  setAppTheme,
  showPopup,
} from "../../../../../redux/actions/app";
import { createSurvey } from "../../../../../core/axios/services";
import { logoutUser } from "../../../../../redux/actions/user";
import Loader from "../../../../components/Loader/Loader";

const GlobalImprovementsSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();

  const getOveralHealthForCurrentLanguage = () => {
    return survey?.settings?.clinic?.overallHealths.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  const [wentThroughBodyMetrics, setWentThroughBodyMetrics] = useState(false);
  const initialConcerns = [
    {
      name: "overallCurrent",
      topText: getOveralHealthForCurrentLanguage()?.question1Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question1Title,
      text: "", //getOveralHealthForCurrentLanguage()?.question1Description,
      leftOption: getOveralHealthForCurrentLanguage()?.question1LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question1HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[7]?.concernRate
          ? survey.data.globalHealth[7].concernRate
          : 0,
      mandatory: false,
      hasTextInput: false,
      hasRate: true,
    },
    {
      name: "overallDesired",
      topText: getOveralHealthForCurrentLanguage()?.question2Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question2Title,
      text: "", //getOveralHealthForCurrentLanguage()?.question2Description,
      leftOption: getOveralHealthForCurrentLanguage()?.question2LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question2HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[8]?.concernRate
          ? survey.data.globalHealth[8].concernRate
          : 0,
      mandatory: false,
      hasTextInput: false,
      hasRate: true,
    },
  ];
  const advancedInitialConcerns = [
    {
      name: "overallCurrent",
      topText: getOveralHealthForCurrentLanguage()?.question1Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question1Title,
      text: '',
      leftOption: getOveralHealthForCurrentLanguage()?.question1LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question1HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[7]?.concernRate
          ? survey.data.globalHealth[7].concernRate
          : 0,
      mandatory: false,
      hasTextInput: false,
      hasRate: true,
    },
    {
      name: "overallDesired",
      topText: getOveralHealthForCurrentLanguage()?.question2Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question2Title,
      text: '',
      leftOption: getOveralHealthForCurrentLanguage()?.question2LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question2HighScale,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[8]?.concernRate
          ? survey.data.globalHealth[8].concernRate
          : 0,
      mandatory: false,
      hasTextInput: false,
      hasRate: true,
    },
    {
      name: "howLongToAchieve",
      topText: "",
      headline: getOveralHealthForCurrentLanguage()?.question4Question,
      concern:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[12]?.concern
          ? survey.data.globalHealth[12].concern
          : "",
      mandatory: false,
      hasTextInput: true,
      hasRate: false,
      characterLimit: 120,
    },
    {
      name: "howImportant",
      topText: getOveralHealthForCurrentLanguage()?.question5Subtitle,
      headline: getOveralHealthForCurrentLanguage()?.question5Question,
      concernRate:
        survey.data &&
        survey.data.globalHealth &&
        survey.data.globalHealth[13]?.concernRate
          ? survey.data.globalHealth[13].concernRate
          : 0,
      leftOption: getOveralHealthForCurrentLanguage()?.question5LowScale,
      rightOption: getOveralHealthForCurrentLanguage()?.question5HighScale,
      mandatory: false,
      hasTextInput: false,
      hasRate: true,
    },
    {
      name: "markThings",
      topText: "",
      headline: getOveralHealthForCurrentLanguage()?.question6Question,
      text: "", //getOveralHealthForCurrentLanguage()?.question6Description,
      options: [
        {
          id: 10,
          name: "firstTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer1,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[0]?.selected
              : false,
        },
        {
          id: 11,
          name: "secondTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer2,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[1]?.selected
              : false,
        },
        {
          id: 12,
          name: "thirdTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer3,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[2]?.selected
              : false,
        },
        {
          id: 13,
          name: "fourthTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer4,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[3]?.selected
              : false,
        },
        {
          id: 14,
          name: "fifthTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer5,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[4]?.selected
              : false,
        },
        {
          id: 15,
          name: "sixthTick",
          text: getOveralHealthForCurrentLanguage()?.question6Answer6,
          isOther: true,
          selected:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[14]?.options
              ? survey.data.globalHealth[14]?.options[5]?.selected
              : false,
        },
      ],
      hasTextInput: false,
      hasRate: false,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    let rightConcerns = initialConcerns;
    if (survey.settings.type === 2) {
      rightConcerns = advancedInitialConcerns;
    }
    survey.settings.bodyMetrics.reverse().forEach((metric, i) => {
      const index = rightConcerns.findIndex((c) => c.title === metric.title);
      if (index === -1) {
        rightConcerns.unshift({
          name: metric.title,
          topText: metric.title,
          headline: metric.question,
          concernRate:
            survey.data &&
            survey.data.globalHealth &&
            survey.data.globalHealth[i]?.concernRate
              ? survey.data.globalHealth[i].concernRate
              : 0,
          leftOption: metric.lowScaleName,
          rightOption: metric.highScaleName,
          mandatory: false,
          hasTextInput: false,
          hasRate: true,
        });
      }
    });

    dispatch(updateSurveyPage(4));
    setConcerns(rightConcerns);

    if (survey.data == null) {
      survey.data = { globalHealth: rightConcerns };
    } else {
      survey.data.globalHealth = rightConcerns;
    }
    dispatch(updateSurveyData(survey.data));
  }, []);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "completeSurvey":
          sendSurveyToApi();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }

    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

   const [isSendingSurveyToApi, setIsSendingSurveyToApi] = useState(false);
  const sendSurveyToApi = async () => {
    setIsSendingSurveyToApi(true);
    try {
      return await createSurvey(survey).then((res) => {
        setIsSendingSurveyToApi(false);
        dispatch(updateSurveyPage(5));
        navigate("/survey/" + code + "/thank-you");
      });
    } catch (error) {
      setIsSendingSurveyToApi(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const checkIfBodyMetricsSelected = () => {
    if (
      concerns[0].concernRate !== 0 &&
      concerns[1].concernRate !== 0 &&
      concerns[2].concernRate !== 0 &&
      concerns[3].concernRate !== 0 &&
      concerns[4].concernRate !== 0 &&
      concerns[5].concernRate !== 0 &&
      concerns[6].concernRate !== 0 &&
      concerns[7].concernRate !== 0 &&
      concerns[8].concernRate !== 0 &&
      concerns[9].concernRate !== 0 &&
      concerns[10].concernRate !== 0 &&
      concerns[11].concernRate !== 0
    ) {
      return true;
    }
  };

  const nextPage = () => {
    if (survey.settings.type === 3 && checkIfBodyMetricsSelected()) {
      dispatch(updateSurveyPage(5));
      navigate("/survey/" + code + "/lifestyle-changes");
    } else {
      if (!checkIfBodyMetricsSelected()) {
        scrollTo();
        showGlobalSnackbar(i18n.t("pleaseCompleteBodyMetrics"), 2000);
        return;
      }

      const data = {
        showPopup: true,
        data: {
          title: i18n.t("confirmCompleteSurvey"),
          confirmButton: i18n.t("confirmCompleteSurveyButton"),
          denyButton: i18n.t("denyCompleteSurveyButton"),
          type: "completeSurvey",
        },
      };

      dispatch(showPopup(data));
    }
  };

  const previousPage = () => {
    dispatch(updateSurveyPage(3));
    navigate("/survey/" + code + "/health-concerns");
  };

  useEffect(() => {}, [concerns]);

  const updateConcern = (index, value, type) => {
    if (type === "text") {
      concerns[index].concern = value;
    } else {
      concerns[index].concernRate = value;
    }

    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { globalHealth: concerns };
    } else {
      survey.data.globalHealth = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const updateOptions = (data, index) => {
    concerns[index].options = data;
    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { globalHealth: concerns };
    } else {
      survey.data.globalHealth = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 3"}
        headline={getOveralHealthForCurrentLanguage()?.title}
        text={getOveralHealthForCurrentLanguage()?.desc}
        videoLink={getOveralHealthForCurrentLanguage()?.videoUrl}
        buttonText={i18n.t("next")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              <HealthConcernText
                setConcernText={(value) => updateConcern(index, value, "text")}
                setConcernRate={(value) => updateConcern(index, value, "rate")}
                mandatory={concern.mandatory}
                text={concern.text}
                concernRate={concern.concernRate}
                topText={concern.topText}
                headline={concern.headline}
                themeColor={themeColor}
                hasTextInput={concern.hasTextInput}
                leftOption={concern.leftOption}
                rightOption={concern.rightOption}
                id={concern.name}
                i18n={i18n}
                hasRate={concern.hasRate}
                hasSpecificRate={false}
                options={concern.options ? concern.options : null}
                setOptions={(data) => updateOptions(data, index)}
                concernText={concern.concern}
                characterLimit={concern.characterLimit}
              />
            </div>
          );
        })}
      </div>
      {
          isSendingSurveyToApi && <Loader/>
      }
      <div className={"buttonsContainer"}>
        <div className={"nextContainer"} onClick={() => nextPage()}>
          <Button
              disabled={isSendingSurveyToApi}
            color={themeColor}
            mode={"small"}
            text={
              survey.settings.type === 3
                ? i18n.t("next")
                : i18n.t("completeSurvey")
            }
          />
        </div>
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previous")} />
        </div>
      </div>
    </div>
  );
};

export default GlobalImprovementsSurvey;
