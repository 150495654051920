import React, { useEffect, useMemo, useState } from "react";
import "./SurveyMain.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  doSearch,
  getPreviousSurvey,
  getSettingsFromCode,
  getSurveyById,
  isSurveyEditable,
} from "../../../../../core/axios/services";
import { logoutUser } from "../../../../../redux/actions/user";
import { Outlet } from "react-router-dom";
import {
  updatePreviousSurveyData,
  updatePreviousSurveyPage,
  updateSurveyData,
  updateSurveyPatient,
  updateSurveySetting,
} from "../../../../../redux/actions/survey";
import {
  resetPopUp,
  setAppTheme,
  setLoadingAction, setThemeCustomColor,
  showSnackbar,
} from "../../../../../redux/actions/app";
import FullStepper from "../../../../components/Survey/FullStepper/FullStepper";
import getThemes from "../../../../../constants/colors/getTheme";
import ConfirmPopUp from "../../../../components/Admin Panel/ConfirmPopUp/ConfirmPopUp";
import SplashScreen from "../../../SplashScreen/SplashScreen";

import LogoImg from "../../../../components/Comon/Logo/logo.png";

const SurveyMain = ({
  showGlobalSnackbar,
  type = "new",
  app,
  isNext,
  isEditable,
  themeColor,
}) => {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.survey);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { code } = useParams();
  const { previousId } = useParams();
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(true);

  // console.log(user.user.clinic, "user");

  useEffect(() => {
    if (
      window.location.host == "stage.chiropracticoutcomes.com" ||
      window.location.host == "chiropracticoutcomes.com"
    ) {
      navigate("/not-found");
    }
  }, []);

  // on component close
  useEffect(
    () => () => {
      // dispatch(setLoadingAction(true));
      dispatch(setAppTheme(null));
      dispatch(updateSurveySetting(null));
      dispatch(updateSurveyPatient(null));
      dispatch(updateSurveyData(null));
    },
    []
  );

  useEffect(() => {
    // resetPopup & snackbar, in case it was left open
    dispatch(resetPopUp());
    dispatch(
      showSnackbar({
        showSnackbar: false,
        snackbarMessage: "",
      })
    );
  }, []);

  useEffect(() => {
    if (isEditable === true) {
      checkIfEditable(surveyId);
    }
  }, [isEditable]);

  const checkIfEditable = (id) => {
    isSurveyEditable(id, user).then((res) => {
      if (res.data.isEditable === false) {
        navigate("/error");
      }
    });
  };

  useEffect(() => {
    getSettings();

    if (isNext) {
      getPreviousSurveyData();
    }
  }, []);

  useEffect(() => {
    if (survey) {
      if (survey?.settings != null && survey?.settings.clinic) {
        // if (survey.settings.clinic.logo == null || survey.settings.clinic.logo === '') {
        //     survey.settings.clinic.logo = "/assets/logo.png";
        // }

        // if (survey.settings.language.id === 1) {
        //   // english
        //   i18n.changeLanguage("en");
        // } else if (survey.settings.language.id === 2) {
        //   // spanish
        //   i18n.changeLanguage("es");
        // }

        i18n.changeLanguage(survey.settings.language.code);

        console.log(
          survey.settings.language.code,
          "survey.settings.language.code"
        );

        dispatch(setAppTheme(survey.settings.clinic.theme.color));
      }
    }
  }, [survey.settings]);

  const [seoValues, setSeoValues] = useState({
    title: "Default Title",
    description: "Default description",
    address: "Default address",
  });

  const getSettings = async () => {
    try {
      return await getSettingsFromCode(code).then((res) => {
        dispatch(updateSurveySetting(res.data.data));
        localStorage.setItem("link", res.data.data?.clinicTermsUrl);
        setLoading(false);

        setSeoValues(res.data.data.clinic);
        console.log(res.data.data.clinic, "ress");
      });
    } catch (error) {
      navigate("/error");
    }
  };

  const getPreviousSurveyData = async () => {
    try {
      return await getPreviousSurvey(previousId).then((res) => {
        dispatch(updatePreviousSurveyData(res.data.data));
      });
    } catch (error) {
      navigate("/error");
    }
  };

  const imageOnLoad = (event) => {
    if (event.currentTarget.className !== "error") {
      event.currentTarget.className = "logo";
    }
  };

  const imageOnError = (event) => {
    event.currentTarget.src = "/assets/logotm.png";
    event.currentTarget.className = "logo";
  };

  return (
    <div>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="survey-main">
          <div className={"survey-box"}>
            {survey?.settings?.clinic?.logo ? (
              <img
                alt={"logo"}
                onLoad={imageOnLoad}
                className={"unvisible-logo"}
                src={survey?.settings?.clinic?.logo}
              />
            ) : (
              <img className="logoSet" alt={"logo"} src={LogoImg} />
            )}

            <Outlet />
          </div>
          <ConfirmPopUp app={app} />
        </div>
      )}
    </div>
  );
};

export default SurveyMain;
