// App actions
export const LOGIN = "LOGIN";
export const ADD_TOKEN = "ADD_TOKEN";
export const RESTORE_TOKEN = "RESTORE_TOKEN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const LOADING = "LOADING";
export const LOADING_API = "LOADING_API";
export const SHOW_CAMERA = "SHOW_CAMERA";
export const LANGUAGE = "LANGUAGE";
export const NOTIFICATION = "NOTIFICATION";
export const CHANGE_THEME = "CHANGE_THEME";
export const GET_APP_DATA = "GET_APP_DATA";
export const CHANGE_THEME_CUSTOM_COLOR = "CHANGE_THEME_CUSTOM_COLOR";
export const SECRET_LOGIN = "SECRET_LOGIN";
export const SHOW_POPUP = "SHOW_POPUP";
export const CONFIRM_POPUP = "CONFIRM_POPUP";
export const DENY_POPUP = "DENY_POPUP";
export const RESET_POPUP = "RESET_POPUP";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_SETTINGS = "UPDATE_SURVEY_SETTINGS";
export const UPDATE_SURVEY_PATIENT = "UPDATE_SURVEY_PATIENT";
export const UPDATE_SURVEY_PATIENT_ID = "UPDATE_SURVEY_PATIENT_ID";
export const UPDATE_SURVEY_PAGE = "UPDATE_SURVEY_PAGE";
export const UPDATE_SURVEY_DATA = "UPDATE_SURVEY_DATA";
export const DELETE_SURVEY_DATA = "DELETE_SURVEY_DATA";
export const UPDATE_PREVIOUS_SURVEY_DATA = "UPDATE_PREVIOUS_SURVEY_DATA";
export const DELETE_PREVIOUS_SURVEY_DATA = "DELETE_PREVIOUS_SURVEY_DATA";
export const UPDATE_CLINIC_SURVEY_SETTINGS = "UPDATE_CLINIC_SURVEY_SETTINGS";

export const DELETE_USER_LOGO = "DELETE_USER_LOGO";
