import { themes } from "..";
export default (property, theme = "default") => {
  try {
    let themeProperty = null;
    if (theme === "default" || theme === "defaultTheme") {
      themeProperty = themes["defaultTheme"][property];
    } else {
      if(property === "mainColor") {
        const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
        if (hexColorRegex.test(theme)) {
          return theme;
        }
      }
      themeProperty = themes["theme"+theme][property] ?? themes["defaultTheme"][property];
    }
    const fallbackProperty = themes.defaultTheme[property];
    return themeProperty || fallbackProperty;
  } catch (error) {
    console.log(error, "themeIs   " + theme, " propertyIs:   " + property);
  }
};
