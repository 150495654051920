import React, { useEffect, useState } from "react";
import "./EditableHealthStressesSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";

const EditableHealthStressesSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();
  const { surveyId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateSurveyPage(2));
  }, []);

  useEffect(() => {
    setConcerns(survey.data.healthStresses);
  }, [survey]);

  useEffect(() => {
    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

  const nextPage = () => {
    dispatch(updateSurveyPage(3));
    navigate("/survey-edit/" + code + "/" + surveyId + "/health-concerns");
  };

  const previousPage = () => {
    dispatch(updateSurveyPage(1));
    navigate("/survey-edit/" + code + "/" + surveyId + "/patient-profile");
  };

  const updateConcern = (data, index) => {
    concerns[index].options = data;
    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { healthStresses: concerns };
    } else {
      survey.data.healthStresses = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 3"}
        headline={survey?.settings?.stressesHeadline}
        text={survey?.settings?.stressesText}
        videoLink={survey?.settings?.stressesVideo}
        buttonText={i18n.t("next")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              <HealthConcernText
                setConcernText={(value) => updateConcern(index, value, "text")}
                setConcernRate={(value) => updateConcern(index, value, "rate")}
                mandatory={concern.mandatory}
                text={concern.text}
                concernRate={concern.concernRate}
                topText={concern.topText}
                headline={concern.headline}
                themeColor={themeColor}
                hasTextInput={false}
                leftOption={i18n.t("notSevere")}
                rightOption={i18n.t("severe")}
                i18n={i18n}
                id={concern.name}
                options={concern.options}
                setOptions={(data) => updateConcern(data, index)}
                concernText={concern.concern}
              />
            </div>
          );
        })}
      </div>
      <div className={"buttonsContainer"}>
        <div className={"nextContainer"} onClick={() => nextPage()}>
          <Button color={themeColor} mode={"small"} text={i18n.t("next")} />
        </div>
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previous")} />
        </div>
      </div>
    </div>
  );
};

export default EditableHealthStressesSurvey;
