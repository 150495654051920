import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import { Box } from "@mui/material";
import useComponentVisible from "../../../../hooks/useComponentVisible";

const Dropdown = ({
  app,
  options,
  selectedOption,
  setSelectedOption,
  label,
  placeholder,
  type,
  disabled,
  show,
  excludeFirstOption = false,
    shortenSelectedLabel = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const [specialClass, setSpecialClass] = useState("");

  useEffect(() => {
    if (type == "practitioner") {
      setSpecialClass("dropdownPractitioner");
    }
  }, [type]);

  useEffect(() => {
    if (type === "role" || type === "settingType") {
      setSelectedObject(
        options?.find((o) => {
          return o.id === selectedOption;
        })
      );
    } else if (
      type === "language" ||
      type === "statistics" ||
      type === "practitioner" ||
      type === "practitionerBulk"
    ) {
      setSelectedObject(
        options?.find((o) => {
          return o.id === selectedOption?.id;
        })
      );
    } else if (type === 'timezone'){
      setSelectedObject(
          options?.find((o) => {
            return o.value === selectedOption?.value;
          })
      );
    }
  }, [selectedOption, options]);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
    }
  }, [isComponentVisible]);

  const chosenOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const openDropdown = () => {
    if (disabled === true) {
      return;
    }
    setIsComponentVisible(true);
    setIsOpen(true);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className="Dropdown" ref={ref}>
      <Box
        className={
          type == "practitionerBulk"
            ? "dropdownLabel dropdownBulk"
            : "dropdownLabel"
        }
      >
        {type !== "practitioner" ? label : null}
      </Box>
      <Box className={"dropdownMain"}>
        {isOpen ? (
          <Box className={"dropdownOpen"} onClick={handleCloseDropdown}>
            <Box className={"dropdownPlaceholder " + specialClass}>
              {" "}
              {placeholder}{" "}
            </Box>
            <img
              src={"/assets/Arrow_Grey.svg"}
              className={"dropdownArrow dropdownArrowOpen " + specialClass}
              alt="dropdownArrow"
            />

            {show && (
              <div className="showDropdownListTitle">
                <h3>Clinic</h3>
                <p onClick={() => chosenOption(options[0])}>
                  {options[0]?.name}
                </p>
                <h3>Practitioners</h3>
              </div>
            )}
            {options?.map((option, index) => {
              if (excludeFirstOption && index === 0) {
                return null; // Skip rendering the first option
              }
              return (
                <Box
                  className={"dropdownOption " + specialClass}
                  key={index}
                  onClick={() => chosenOption(option)}
                >
                  {" "}
                  {(option?.name?.length > 30 && shortenSelectedLabel)
                    ? `${option?.name.substring(0, 30).trim()}...`
                    : option?.name}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box
            className={"dropdownClosed " + specialClass}
            onClick={() => openDropdown()}
          >
            {selectedObject ? (
              <Box className={"selectedOption " + specialClass}>
                {" "}
                {/* {selectedObject?.name} */}
                {(selectedObject?.name?.length > 20 && shortenSelectedLabel)
                  ? `${selectedObject?.name.substring(0, 20).trim()}...`
                  : selectedObject?.name}
              </Box>
            ) : (
              <Box className={"selectedOptionPlaceholder " + specialClass}>
                {" "}
                {placeholder}
              </Box>
            )}

            <img
              src={"/assets/Arrow_Grey.svg"}
              className={"dropdownArrow " + specialClass}
              alt="dropdownArrow"
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Dropdown;
