import React from "react";
import * as types from "../types/actionTypes";
import i18next from "../../i18next";
import {CHANGE_THEME_CUSTOM_COLOR} from "../types/actionTypes";

export const setLoadingAction = (action) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING, payload: action });
  };
};

export const setLoadingApiAction = (action) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_API, payload: action });
  };
};

export const setLanguageAction = (language) => {
  return async (dispatch) => {
    i18next.locale = language;
    try {
      await localStorage.setItem("languagePicked", language);
    } catch (e) {
      alert(e);
    }
    await dispatch({ type: types.LANGUAGE, payload: language });
  };
};

export const setNotificationAction = (data) => {
  return async (dispatch) => {
    await dispatch({ type: types.NOTIFICATION, payload: data });
  };
};

export const getAppData = (data) => {
  return async (dispatch) => {
    await dispatch({ type: types.GET_APP_DATA, payload: data });
  };
};

export const deleteUserLogo = (data) => {
  return async (dispatch) => {
    await dispatch({ type: types.DELETE_USER_LOGO, payload: data });
  };
};

export const showPopup = (data) => {
  return async (dispatch) => {
    await dispatch({ type: types.SHOW_POPUP, payload: data });
  };
};

export const confirmPopup = (data = null) => {
  return async (dispatch) => {
    await dispatch({ type: types.CONFIRM_POPUP, payload: data });
  };
};

export const denyPopup = () => {
  return async (dispatch) => {
    await dispatch({ type: types.DENY_POPUP });
  };
};

export const resetPopUp = () => {
  return async (dispatch) => {
    await dispatch({ type: types.RESET_POPUP });
  };
};

export const showSnackbar = (data) => {
  return async (dispatch) => {
    await dispatch({ type: types.SHOW_SNACKBAR, payload: data });
  };
};

export const addUserToken = (data, rememberMe) => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_TOKEN,
      payload: {
        token: data.accessToken,
        rememberMe,
      },
    });
  };
};

export const setAppTheme = (theme) => {
  return async (dispatch) => {
    await dispatch({ type: types.CHANGE_THEME, payload: theme });
  };
};

//
// export const setThemeCustomColor = (themeCustomColor) => {
//   return async (dispatch) => {
//     await dispatch({ type: types.CHANGE_THEME_CUSTOM_COLOR, payload: themeCustomColor });
//   };
// };
